import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/app.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService) { }
  imageUrl = `/assets/fokida-logo3.png`;
  imageUrl2 = `/assets/sga_img_logo.png`;
  date = new Date().getFullYear()
  links: any;
  faPhoneAlt = faPhoneAlt


  async ngOnInit() {
    let language: string = this.translate.currentLang === undefined ? "el" : this.translate.currentLang; 
    //get links
    this.links = await this.fetchLinks(language);

    //react to the language changing event
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      language = event.lang;
      //get links
      this.links = await this.fetchLinks(language);
    })



    
  }

  async fetchLinks(locale: string) : Promise<any> {
    let links: any = await this.api.getLinks(locale).toPromise();

    //transform links from: number --> /page/number ie 8 --> /page/8
    const keys: string[] = ["dilosi_prosvasimotitas", "politiki_prostasias"];
    keys.forEach((key: string) => {
      links.data.attributes[key] = `/page/${links.data.attributes[key]}`;
    })
    
    return links;
  }

}
