import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  constructor(private api: ApiService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle('Εγγραφή στο newsletter' ); 
  }

  public async send(form: NgForm) {

    if (form.valid) {
      this.spinner.show();
      //create the object that will be sent to the api
      type objType = {
        to: string, //email receiver
        from: string, //email sender
        subject: string, //the subject of the email
        text: string, //the message
        html: string //the html template of the email
      }

      //this will be the message that will be sent with the email
      const htmlTemplate: string = 
      `
        <head>Μήνυμα γνωστοποίησης email μέλους</head>
        <body>
          <h2>Στοιχεία μέλους:</h2>
          <p>Όνομα: ${form.value.name}</p>
          <p>Επώνυμο: ${form.value.lastname}</p>
          <p>Email: ${form.value.email}</p>
          <p>ΑΦΜ: ${form.value.afm}</p>
          <p>Τηλέφωνο: ${form.value.phone}</p>
        </body>
      `

      //defining the object that will be sent to the api
      const myObject: objType = {
        to: "ebear@otenet.gr",
        from: "no-reply@epimelitiriofokidas.gr", 
        subject: "Μήνυμα γνωστοποίησης email μέλους",
        text: "Μήνυμα γνωστοποίησης email μέλους",
        html: htmlTemplate,
      } 
      await this.api.postEmail(myObject).toPromise();

      //snackBar opening for successfull email sending
      this.snackBar.open(
        "Το email στάλθηκε επιτυχώς!", 
        "Το κατάλαβα!", 
        { 
          duration: 5000, 
          panelClass: ["snackBar", "snackBarSuccess"]
        }
      )

    } else {
      //snackBar opening for failed email sending
      this.snackBar.open(
        "Το email δεν στάλθηκε! Επικοινωνήστε με το επιμελητήριο.", 
        "Το κατάλαβα!", 
        {
          duration: 5000,
          panelClass: ["snackBar", "snackBarFailure"]
        }
      )
    }
    this.spinner.hide();
  }
}
