import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  token: string | undefined;
  recaptchaValid: boolean;
  constructor(private api: ApiService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, private title: Title) {
    this.token = undefined;
    this.recaptchaValid = false;
   }

  ngOnInit(): void {
    this.title.setTitle('ΕΠΙΚΟΙΝΩΝΙΑ' ); 
  }

  public async send(form: NgForm) {

    if (form.valid && this.recaptchaValid) {
      this.spinner.show();
      //create the object that will be sent to the api
      type objType = {
        to: string, //email receiver
        from: string, //email sender
        subject: string, //the subject of the email
        text: string, //the message
        html: string //the html template of the email
      }

      //this will be the message that will be sent with the email
      const htmlTemplate: string = 
      `
        <head>Μήνυμα για το Επιμελητήριο Αργολίδας</head>
        <body>
          <h2>Στοιχεία επικοινωνίας αποστολέα:</h2>
          <p>Ονοματεπώνυμο: ${form.value.name}</p>
          <p>Τηλέφωνο: ${form.value.phone}</p>
          <p>Email: ${form.value.email}</p>
          <p>Θέμα: ${form.value.subject}</p>
          <div>
            <p>${form.value.message}<p>
          </div>
        </body>
      `

      //defining the object that will be sent to the api
      const myObject: objType = {
        to: "ebear@otenet.gr",
        from: "no-reply@epimelitiriofokidas.gr", 
        subject: "New email",
        text: "Μήνυμα από τη φόρμα επικοινωνίας επιμελητηρίου Αργολίδας",
        html: htmlTemplate,
      } 
      await this.api.postEmail(myObject).toPromise();

      //snackBar opening for successfull email sending
      this.snackBar.open(
        "Το email στάλθηκε επιτυχώς!", 
        "Το κατάλαβα!", 
        { 
          duration: 5000, 
          panelClass: ["snackBar", "snackBarSuccess"]
        }
      )

    } else {
      //snackBar opening for failed email sending
      this.snackBar.open(
        "Το email δεν στάλθηκε! Βεβαιωθείτε ότι έχετε αποδεχτεί το captcha.", 
        "Το κατάλαβα!", 
        {
          duration: 5000,
          panelClass: ["snackBar", "snackBarFailure"]
        }
      )
    }
    this.spinner.hide();
  }

  statusChanged(event: any) {
    //This function runs every time the value of the captcha changes.
    console.log(event)
    if (event === null) {
      //captcha expired
      this.recaptchaValid = false;
    } else {
      //user clicked the captcha. Captcha is now valid
      this.recaptchaValid = true;
    }
  }
}
